import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
    name: 'location',
    initialState: {
      

        location:{},

        
    },
    reducers: {
      

        locationSuccess: (state, action) => {
            state.location = action.payload;

        },
        clearLocation: (state) => {
            state.location = {};
        },
    }
});

export default locationSlice.reducer;

export const { locationSuccess, clearLocation } = locationSlice.actions;


