import React from 'react';
import {  Modal } from '@mui/material';
import WelcomeUser from './WelcomeUser';

const WelcomeUserModal = ({ open, onClose , onRegisterSuccess}) => {
    return (
        <Modal open={open} onClose={onClose} style={{height: '100%', overflowY:'auto'}}>
            <div>
                <WelcomeUser onRegisterSuccess={onRegisterSuccess}/>
            </div>
        </Modal>
    );
}

export default WelcomeUserModal;
