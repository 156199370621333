import React, { useEffect, useCallback, useState } from 'react';
import { Typography, Button, Grid, Container } from '@mui/material';
import Img from '../logo-m.png';
import Img2 from "../Vector 1.svg";
import FarmIMG from "../Farm mgt.svg";
import { useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router-dom';
import { FaWhatsapp } from "react-icons/fa6";


const WelcomeUser = (onRegisterSuccess) => {

    const theme = useTheme();
    const isPhoneView = useMediaQuery(theme.breakpoints.down('xs'));
    const [agroUserData, setAgroUserData] = useState({});

    const location = useLocation();
    const pathnameParts = location.pathname.split('/');
    const agronomistName = pathnameParts[1];
    const parts = agronomistName.split("-");
    const capitalizedParts = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1));
    const capitalizedFullName = capitalizedParts.join(" ");
   


    const handleRedirectToWhatsApp = () => {

        const url = `https://wa.me/${9860054765}`;
        window.open(url, '_blank');
    };

    const getAgroUserData = useCallback(() => {
        const agroUserDataFromLocalStorage = localStorage.getItem('agroUser');
        if (agroUserDataFromLocalStorage) {
            setAgroUserData(JSON.parse(agroUserDataFromLocalStorage));
        }
    }, []);

    useEffect(() => {
        getAgroUserData();
    }, [getAgroUserData])

    console.log('LOCAL DATA', agroUserData)
    let name = agroUserData?.data?.user?.firstName + ' ' + agroUserData?.data?.user?.lastName;


    return (
        <>
  
                <div style={{ backgroundColor: '#ffffff', padding: 25, minHeight: '100vh' }}>
                    <Container maxWidth="md">

                        <div style={{ textAlign: 'center' }}>
                            <img style={{ marginTop: 6, width: 150, marginBottom: 15 }} src={Img} alt='phyfarm' />
                        </div>

                        <Grid container spacing={3} justifyContent="center" alignContent="center" style={{ marginTop: 10 }}>
                            <Grid item xs={12} md={12}>
                                <img src={Img2} style={{ width: '20%', maxWidth: 400, height: 'auto', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} alt='phyfarm' ></img>
                            </Grid>
                            <Grid item xs={12} md={12}>

                                <img src={FarmIMG} style={{ width: '30%', maxWidth: 400, height: 'auto', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} alt='phyfarm'></img>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h6" gutterBottom style={{ fontFamily: 'sen', marginTop: 10, textAlign: 'center', fontWeight: 'bold' }}>
                                    Congratulations {name} !
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <p style={{ color: 'gray', textAlign: 'center' }}>You are now a part of the {capitalizedFullName}'s Agro group</p>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" onClick={handleRedirectToWhatsApp} style={{ backgroundColor: '#5F9C6A', fontFamily: 'sen', marginTop: 20, borderRadius: 25 }} fullWidth>
                                    <FaWhatsapp style={{ marginRight: 5 }} />Redirect to Whats App
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: isPhoneView ? 0 : 100 }} >

                            <Grid item xs={12}>
                                <Typography align="center" color="textSecondary" style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    Powered by
                                    <img style={{ marginTop: 6, width: 78, marginBottom: 15, marginLeft: 10 }} src={Img} alt='phyfarm' />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
          
        </>
    );
}

export default WelcomeUser;
