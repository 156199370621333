import { Route, Routes } from 'react-router-dom';
import InviteUser from './components/InviteUser';
import { Provider } from 'react-redux'
import store from './store/store';

function App() {
  return (
    <Provider store={store}>
    <Routes>
       <Route path="/:user_url/register-user" element={<InviteUser />}/>
    </Routes>
  </Provider>
  );
}

export default App;
