import React, { useState, useEffect, useCallback } from 'react';
import { Typography, TextField, Button, Grid, Container, InputAdornment, Select, MenuItem, InputLabel } from '@mui/material';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import LocationMap from './LocationMap';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearLocation } from '../reducers/location';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { HiMiniLanguage } from "react-icons/hi2";
import Img from '../logo-m.png';
import WelcomeUserModal from './WelcomeUserModal';

import { useLocation } from 'react-router-dom';

const RegisterChatUser = (onRegisterSuccess) => {

    const [isMap, setIsMap] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cropTypes, setCropTypes] = useState([]);
    const locationFromRedux = useSelector(state => state.locationReducer.location);
    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        phone: false,
        language: false,
        farmName: false,
        farmSize: false,
        farmSizeUnit: false,
        preferredCrops: false,
    })
    const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();
    const pathnameParts = location.pathname.split('/');
    const agronomistName = pathnameParts[1];

    const agronomistIdMap = {
        'phyfarm': '65c0d2a9ac84ae512f3c916a',
        'vikas-chauhan': '65dd7d820e400ca5404f014a',
        'kaushik': '65dd7df10e400ca5404f014b',
        'tarun-bhardwaj': '65dd7e900e400ca5404f014c',
        'sushant-h-surve': '65dd7edd0e400ca5404f014d',
        'dankan': '65dd7f1b0e400ca5404f014e',
        'prem-more': '65dd7f450e400ca5404f014f',
        'kirubhakaran-rajendran': '65dd7f7e0e400ca5404f0150',
        'vijay-m': '65dd7fef0e400ca5404f0151',
        'akash-wadkar': '65dd801d0e400ca5404f0152',
        'hussain': '65dd803d0e400ca5404f0153',
        'onelabs': '662f3e64e8e87191dad6db83'
    };

    const agronomistIdFromParams = agronomistIdMap[agronomistName] || '';


    const getEmptyData = useCallback(() => {
        return {
            user: {
                agronomist_id: agronomistIdFromParams,
                firstName: "",
                lastName: "",
                phone: "",
                language: "English",
                type: "agronomistCustomer",
                country_code: "91"
            },
            farm: {
                name: "",
                size: "",
                size_unit: "acre"
            },
            preferredCrops: []

        }
    }, [agronomistIdFromParams]);


    const [formData, setFormData] = useState(getEmptyData());



    const handleClose = () => {
        setOpenModal(false);
    };

    const handleMapOpen = () => {
        setOpenModal(true);
        setIsMap(true);
    }

    const clearFormFields = useCallback(() => {

        setFormData(getEmptyData());
    }, [getEmptyData]);




    const validatePhone = (phone) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phone);
    };

    const fetchCropTypes = useCallback(async () => {
        const url = 'https://v3.api.phyfarm.com/api/mgmt/phyfarmUsers/listCrops';
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
        };

        const bodyData = {
            orders: [
                {
                    column: 'id',
                    order: 'asc',
                },
            ],
            filters: {},
        };

        try {
            let response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(bodyData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setCropTypes(data.data.crops.Fruits);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []);

    useEffect(() => {
        fetchCropTypes();
    }, [fetchCropTypes]);




    const handleRegisterUser = () => {

        if (!formData.user.firstName || !formData.user.lastName || !formData.user.phone) {

            setErrors(prevErrors => ({
                ...prevErrors,
                firstName: !formData.user.firstName,
                lastName: !formData.user.lastName,
                phone: !formData.user.phone || !validatePhone(formData.user.phone),

            }));
            return;
        }

        if (formData.user.agronomist_id === "" || formData.farm.size === "" || formData.farm.size_unit === "") {
            setLoading(false);
            return;
        }


        setErrors({
            firstName: false,
            lastName: false,
            phone: false,
            language: false,
            farmName: false,
            farmSize: false,
            farmSizeUnit: false,
            preferredCrops: false,
        })

        if (!formData.user.firstName) {
            setErrors((prevErrors) => ({ ...prevErrors, firstName: true }));
        }
        if (!formData.user.lastName) {
            setErrors((prevErrors) => ({ ...prevErrors, lastName: true }));
        }
        if (!formData.user.phone || !validatePhone(formData.user.phone)) {
            setErrors((prevErrors) => ({ ...prevErrors, phone: true }));
        }
        if (!formData.user.language) {
            setErrors((prevErrors) => ({ ...prevErrors, language: true }));
        }
        if (!formData.farm.name) {
            setErrors((prevErrors) => ({ ...prevErrors, farmName: true }));
        }
        if (!formData.farm.size || formData.farm.size.trim() === "") {
            setErrors((prevErrors) => ({ ...prevErrors, farmSize: true }));
            return;
        }
        if (!formData.farm.size_unit) {
            setErrors((prevErrors) => ({ ...prevErrors, farmSizeUnit: true }));
        }
        if (!formData.preferredCrops.length) {
            setErrors((prevErrors) => ({ ...prevErrors, preferredCrops: true }));
        }


        try {

            setLoading(true);


            const payload = {
                user: {
                    agronomist_id: agronomistIdFromParams,
                    firstName: formData.user.firstName,
                    lastName: formData.user.lastName,
                    phone: formData.user.phone,
                    language: formData.user.language,
                    type: formData.user.type,
                    country_code: formData.user.country_code,
                },
                farm: {
                    name: `${formData.user.firstName} ${formData.user.lastName} Farms`,
                    size: parseInt(formData.farm.size, 10),
                    size_unit: formData.farm.size_unit,
                },
                location: {
                    city: locationFromRedux.city,
                    state: locationFromRedux.state,
                    pincode: locationFromRedux.pincode,
                    address: locationFromRedux.address,
                    country: locationFromRedux.country,
                    latitude: locationFromRedux.latitude,
                    longitude: locationFromRedux.longitude,
                },
                preferred_crops: formData.preferredCrops.map(id => ({ id })),

            }

            // console.log(payload);



            fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistcustomer/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c', // Replace with your actual access token
                },
                 body: JSON.stringify(payload),
            })
                .then(response => response.json())
                .then(data => {

                    console.log('API response:', data);
                    if (!data.error) {


                        setLoading(false);
                        getEmptyData();
                        dispatch(clearLocation());
                        clearFormFields();
                        setIsMap(false);
                        localStorage.setItem('agroUser', JSON.stringify(data));
                        setWelcomeModalOpen(true);


                    } else {
                        console.log(data);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error('Error making API call:', error);
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }


    }

    return (
        <>
            <div style={{ backgroundColor: '#ECF1F1', padding: 25, minHeight: '100vh' }}>
                <Container maxWidth="md">

                    <div style={{ textAlign: 'center' }}>
                        <img style={{ marginTop: 6, width: 150, marginBottom: 15 }} src={Img} alt='phyfarm' />
                    </div>
                    <Typography variant="h6" gutterBottom style={{ fontFamily: 'sen' }}>
                        Personal details
                    </Typography>

                    <Grid container spacing={3}  >
                        <Grid item xs={12} md={6}>

                            <InputLabel style={{ fontFamily: 'sen', marginBottom: 10, marginTop: 10 }}>First Name</InputLabel>
                            <TextField
                                fullWidth
                                value={formData.user.firstName}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        user: {
                                            ...formData.user,
                                            firstName: e.target.value
                                        }
                                    });
                                  
                                    setErrors(prevErrors => ({
                                        ...prevErrors,
                                        firstName: false
                                    }));
                                }}
                                placeholder='Enter First Name'
                                error={errors.firstName}

                                color='success'
                                sx={{ backgroundColor: 'white' }}

                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon style={{ color: '#5F9C6A' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>

                            <InputLabel style={{ fontFamily: 'sen', marginBottom: 10, marginTop: 10 }}>Last Name</InputLabel>
                            <TextField
                                fullWidth
                                error={errors.lastName}
                                placeholder='Enter Last Name'
                                value={formData.user.lastName}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        user: {
                                            ...formData.user,
                                            lastName: e.target.value
                                        }
                                    });
                                  
                                    setErrors(prevErrors => ({
                                        ...prevErrors,
                                        lastName: false
                                    }));
                                }}

                                color='success'
                                sx={{ backgroundColor: 'white' }}

                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon style={{ color: '#5F9C6A' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel style={{ fontFamily: 'sen', marginBottom: 10, marginTop: 10 }}>Phone Number</InputLabel>
                            <TextField
                                error={errors.phone}
                                color='success'
                                value={formData.user.phone}
                                placeholder='Enter Phone Number'
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        user: {
                                            ...formData.user,
                                            phone: e.target.value
                                        }
                                    });
                                  
                                    setErrors(prevErrors => ({
                                        ...prevErrors,
                                        phone: false
                                    }));
                                }}

                                fullWidth
                                sx={{ backgroundColor: 'white' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LocalPhoneIcon style={{ color: '#5F9C6A' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>

                            <InputLabel id="test-select-label" style={{ fontFamily: 'sen', marginBottom: 10, marginTop: 10 }}>Language Preferred</InputLabel>
                            <Select
                                error={errors.language}
                                color='success' sx={{ backgroundColor: 'white' }} fullWidth
                                // Set the value from your formData state
                                value={formData.user.language}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    user: {
                                        ...formData.user,
                                        language: e.target.value
                                    }
                                })}

                                name="language"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <HiMiniLanguage style={{ color: '#5F9C6A' }} />
                                        </InputAdornment>
                                    ),
                                }}

                            >
                                <MenuItem value="English">English</MenuItem>
                                <MenuItem value="Hindi">Hindi</MenuItem>
                                <MenuItem value="Marathi">Marathi</MenuItem>

                            </Select>

                        </Grid>
                    </Grid>

                    <Typography variant="h6" gutterBottom style={{ fontFamily: 'sen', marginTop: 10 }}>
                        Farm Details
                    </Typography>

                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={6}>
                            <div>
                                <InputLabel style={{ fontFamily: 'sen', marginBottom: 10, marginTop: 20 }}>Farm Location</InputLabel>
                                {isMap ? (


                                    <div style={{ display: 'flex' }}>

                                        <Typography
                                        >{locationFromRedux && locationFromRedux.address}</Typography>
                                        <Button color='success' onClick={handleMapOpen} sx={{ marginLeft: 5 }}>Change</Button>
                                    </div>

                                ) : (
                                    <div>

                                        <Button variant="outlined" onClick={handleMapOpen} style={{ backgroundColor: 'white', color: 'black', width: 310 }}>
                                            <AddLocationAltOutlinedIcon style={{ marginRight: 5 }} />
                                            Pin location
                                        </Button>
                                    </div>

                                )}
                            </div>

                        </Grid>


                        <Grid item xs={12} md={6}>
                            <InputLabel style={{ fontFamily: 'sen', marginBottom: 10 }}>Farm Size</InputLabel>
                            <TextField
                                color='success'
                                error={errors.farmSize}
                                fullWidth
                                onChange={(e) => setFormData({
                                    ...formData,
                                    farm: {
                                        ...formData.farm,
                                        size: e.target.value
                                    }
                                })}
                                value={formData.farm.size}
                                sx={{ backgroundColor: 'white' }}
                                label="Enter Farm Size"

                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputLabel id="test-select-label" style={{ fontFamily: 'sen', marginBottom: 10 }}>Farm Size Unit</InputLabel>

                            <Select
                                error={errors.farmSizeUnit}
                                color='success' sx={{ backgroundColor: 'white' }} fullWidth
                                // Set the value from your formData state
                                value={formData.farm.size_unit}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    farm: {
                                        ...formData.farm,
                                        size_unit: e.target.value
                                    }
                                })}

                                name="size_unit"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <HiMiniLanguage style={{ color: '#5F9C6A' }} />
                                        </InputAdornment>
                                    ),
                                }}

                            >
                                <MenuItem value="acre">Acre</MenuItem>
                                <MenuItem value="hectare">Hectare</MenuItem>
                                <MenuItem value="sqft">Sqft</MenuItem>
                                <MenuItem value="sqmt">Sqmt</MenuItem>

                            </Select>
                        </Grid>
                        <Grid item xs={12} md={6}>

                            <InputLabel id="test-select-label" style={{ fontFamily: 'sen', marginBottom: 10 }}>Crop Name</InputLabel>
                            <Select

                                color='success'
                                sx={{ backgroundColor: 'white' }}
                                fullWidth
                                multiple
                                // Set the value from your formData state   
                                value={formData.preferredCrops}

                                onChange={(e) => setFormData({
                                    ...formData,
                                    preferredCrops: e.target.value
                                })}
                                name="preferredLanguage"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <HiMiniLanguage style={{ color: '#5F9C6A' }} />
                                        </InputAdornment>
                                    ),
                                }}

                            >
                                {
                                    cropTypes.map((crop) => {
                                        return (
                                            <MenuItem value={crop.id}>{crop.name}</MenuItem>
                                        )
                                    })
                                }


                            </Select>
                        </Grid>

                        <LocationMap style={{ display: openModal ? 'block' : 'none' }} open={openModal} handleClose={handleClose} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={handleRegisterUser} variant="contained" style={{ backgroundColor: '#5F9C6A', fontFamily: 'sen', marginTop: 20, borderRadius: 25 }} fullWidth>
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Register'}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" color="textSecondary" style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            Powered by
                            <img style={{ marginTop: 6, width: 78, marginBottom: 15, marginLeft: 10 }} src={Img} alt='phyfarm' />
                        </Typography>
                    </Grid>

                </Container>
            </div>
            <WelcomeUserModal
                open={welcomeModalOpen}
                onClose={() => setWelcomeModalOpen(false)}
            />

        </>
    );
}

export default RegisterChatUser;
