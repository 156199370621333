import React from 'react';
import {  Modal } from '@mui/material';
import RegisterChatUser from './RegisterChatUser';

const RegisterUserModal = ({ open, onClose, onRegisterSuccess }) => {
    return (
        <Modal open={open} onClose={onClose} style={{height: '100%', overflowY:'auto'}}>
            <div>
                <RegisterChatUser onRegisterSuccess={onRegisterSuccess} />
            </div>
        </Modal>
    );
}

export default RegisterUserModal;
