import { configureStore } from '@reduxjs/toolkit'
import loginReducer from '../reducers/reducers';
import locationReducer from '../reducers/location';




const store = configureStore({
    reducer: {
         loginReducer,
         locationReducer,
      
    }
});

export default store;